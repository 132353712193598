<template>
  <div>
    <!--Title Bar-->
    <title-bar :title-value="'FPL/SMI'" />

    <!-- Filter -->
    <v-toolbar flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span></v-btn
          >
        </template>

        <v-card width="400px">
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>

                <!--Type-->
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    :items="valueListsStore.classificationTypes"
                    item-text="value"
                    item-value="id"
                    label="Type"
                    placeholder=" "
                    persistent-placeholder
                    v-model="f_type"
                    key="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>

                <!--Family Size-->
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="d_familySize"
                    label="Family Size"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--Month-->
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    :items="valueListsStore.months"
                    item-text="value"
                    item-value="id"
                    label="Month"
                    placeholder=" "
                    persistent-placeholder
                    v-model="d_month"
                    key="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>

                <!--Year-->
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="d_year"
                    label="Year"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <!--Entry Dialog-->
      <income-classification />

      <!--Duplicate Button-->
      <v-tooltip
        top
        :color="incomeClassificationsStore.filters.selectedID.length > 0 ? 'primary' : 'grey lighten-1'"
      >
        <template #activator="{ on }">
          <v-btn
            icon
            v-on="on"
            @click="incomeClassificationsStore.filters.selectedID.length > 0 ? openDuplicateDialog() : ''"
          >
            <v-icon
              small
              :color="incomeClassificationsStore.filters.selectedID.length > 0 ? 'primary' : 'grey lighten-1'"
            >
              fal fa-clone
            </v-icon>
          </v-btn>
        </template>
        <span>Duplicate Selection</span>
      </v-tooltip>
    </v-toolbar>

    <!--Duplicate Dialog-->
    <v-dialog v-model="showDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Duplicate Income?</span>
          <v-spacer />
          <v-btn outlined rounded text @click.native="closeDuplicateDialog()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="12">
                Do you wish to duplicate all selected items?
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            rounded
            color="primary"
            @click.native="duplicateIncome()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Pagination-->
    <pagination
      :show="
        incomeClassificationsStore.incomeClassifications.data &&
          incomeClassificationsStore.incomeClassifications.data.length > 0
      "
      :showTotal="true"
      :currentPage="incomeClassificationsStore.incomeClassifications.current_page"
      :lastPage="incomeClassificationsStore.incomeClassifications.last_page"
      :total="incomeClassificationsStore.incomeClassifications.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--Table-->
      <v-simple-table
        v-if="
          incomeClassificationsStore.incomeClassifications.data &&
            incomeClassificationsStore.incomeClassifications.data.length > 0
        "
        :fixed-header="true"
        class="my-2"
      >
        <template #default>
          <thead>
            <tr>
              <th class="text-left grey--text text--darken-1">Type</th>
              <th class="text-left grey--text text--darken-1">Month</th>
              <th class="text-left grey--text text--darken-1">Year</th>
              <th class="text-left grey--text text--darken-1">Family Size</th>
              <th class="text-left grey--text text--darken-1">Income Start</th>
              <th class="text-left grey--text text--darken-1">Income End</th>
              <th class="text-left grey--text text--darken-1">Percentage</th>
              <th class="text-left grey--text text--darken-1">
                <v-btn rounded @click="selectDeselectAll()" color="primary" class="py-0"
                  ><v-icon small left>fal fa-check-double</v-icon>All</v-btn
                >
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="clickable"
              v-for="(item, index) in incomeClassificationsStore.incomeClassifications.data"
              :key="item.id"
              @click="openEntry(item.id)"
            >
              <td class="text-left light-blue--text text--darken-4">
                {{ $_switchClassification(item.f_type) }}
              </td>
              <td class="text-left light-blue--text text--darken-4">
                {{ $_monthNumToText(item.d_month) }}
              </td>
              <td class="text-left light-blue--text text--darken-4">{{ item.d_year }}</td>
              <td class="text-left light-blue--text text--darken-4">{{ item.d_familySize }}</td>
              <td class="text-left light-blue--text text--darken-4">{{ item.d_incomeStart }}</td>
              <td class="text-left light-blue--text text--darken-4">{{ item.d_incomeEnd }}</td>
              <td class="text-left light-blue--text text--darken-4">{{ item.d_percentage }}</td>
              <td class="text-left light-blue--text text--darken-4" style="width:10%">
                <v-checkbox
                  dense
                  :key="index"
                  v-model="selectedID"
                  :value="item.id"
                  color="primary"
                  class="pr-2"
                  @click.stop=""
                  multiple
                >
                </v-checkbox>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-list v-else class="py-2">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="error--text text-subtitle-1 text-xs-center"
              >No Income Classifications Exist</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import IncomeClassification from "@/components/IncomeClassification";
import Pagination from "@/components/Pagination";

const { mapFields } = createHelpers({
  getterType: "incomeClassificationsStore/getFilterField",
  mutationType: "incomeClassificationsStore/updateFilterField"
});

export default {
  name: "IncomeClassifications",
  mixins: [mixin],
  components: {
    TitleBar,
    IncomeClassification,
    Pagination
  },
  computed: {
    ...mapState({
      incomeClassificationsStore: state => state.incomeClassificationsStore,
      progressStore: state => state.progressStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields(["page", "f_type", "d_year", "d_month", "d_familySize", "selectedID"])
  },
  data() {
    return {
      filterMenu: false,
      showDialog: false
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.dispatch("progressStore/set", true);
      /*clear checkbox selections*/
      this.$store.dispatch("incomeClassificationsStore/clearSelected");
      /* Grab filters */
      const filters = this.$store.getters["incomeClassificationsStore/getFilters"];
      /* Records based on filters */
      await this.$store.dispatch("incomeClassificationsStore/fetch", filters);
      this.$store.dispatch("progressStore/set", false);
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    clearFilterColumns() {
      this.f_type = "";
      this.d_year = "";
      this.d_month = "";
      this.d_familySize = "";
      this.page = 1;
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    showFiltered() {
      if (this.f_type || this.d_year || this.d_month || this.d_familySize) {
        return true;
      }
      return false;
    },

    async openEntry(id) {
      await this.$store.dispatch("incomeClassificationsStore/entry", id);
    },

    selectDeselectAll() {
      this.$store.dispatch("incomeClassificationsStore/selectDeselectAll");
    },

    openDuplicateDialog() {
      this.showDialog = true;
    },

    closeDuplicateDialog() {
      this.showDialog = false;
    },

    async duplicateIncome() {
      //var userChoice = confirm("Do you wish to duplicate all selected items?");
      //if (userChoice) {
        //retrieve all selected id's from the selectedID state
      const selected = this.$store.getters["incomeClassificationsStore/getSelected"];
      const data = {
        items: selected
      };
      await this.$store.dispatch("incomeClassificationsStore/duplicateSelected", data);
      this.initialize();
      //}
      this.closeDuplicateDialog();
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
