<template>
  <div>
    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add FPL/SMI</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="600">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >FPL/SMI Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" md="12">
                  <v-row dense>
                    <!--Type FMI or SMI-->
                    <v-col cols="12" sm="12" md="4">
                      <v-autocomplete
                        :items="valueListsStore.classificationTypes"
                        item-text="value"
                        item-value="id"
                        label="Type"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_type"
                        key="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Month-->
                    <v-col cols="12" sm="12" md="4">
                      <v-autocomplete
                        :items="valueListsStore.months"
                        item-text="value"
                        item-value="id"
                        label="Month"
                        placeholder=" "
                        persistent-placeholder
                        v-model="d_month"
                        key="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>

                    <!--Year-->
                    <v-col cols="12" sm="12" md="4">
                      <v-text-field
                        v-model="d_year"
                        label="Year"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>

                    <!--Family Size-->
                    <v-col cols="12" sm="12" md="4">
                      <v-text-field
                        v-model="d_familySize"
                        label="Family Size"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Income Begin-->
                    <v-col cols="12" sm="12" md="4">
                      <v-text-field
                        v-model="d_incomeStart"
                        label="Income Begin (>)"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>

                    <!--Income End-->
                    <v-col cols="12" sm="12" md="4">
                      <v-text-field
                        v-model="d_incomeEnd"
                        label="Income End (>=)"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Percentage-->
                    <v-col cols="12" sm="12" md="4">
                      <v-text-field
                        v-model="d_percentage"
                        label="Percentage"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";

const { mapFields } = createHelpers({
  getterType: "incomeClassificationsStore/getEntryField",
  mutationType: "incomeClassificationsStore/updateEntryField"
});

export default {
  name: "IncomeClassification",
  mixins: [mixin],
  computed: {
    ...mapState({
      incomeClassificationsStore: state => state.incomeClassificationsStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields([
      "show",
      "valid",
      "mode",
      "id",
      "d_month",
      "d_year",
      "d_familySize",
      "d_incomeStart",
      "d_incomeEnd",
      "d_percentage",
      "f_type",
      "f_status",
      "f_show"
    ])
  },
  data() {
    return {};
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("incomeClassificationsStore/resetEntry");
    },

    async newEntry() {
      this.resetEntry();

      this.mode = 1;
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["incomeClassificationsStore/getEntry"];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch("incomeClassificationsStore/create", entry);
        } else {
        /* Edit */
          await this.$store.dispatch("incomeClassificationsStore/update", entry);
        }
        this.cancelEntry();
      }
    },

    addOption() {
      this.$store.dispatch("incomeClassificationsStore/addOption");
    },

    removeOption(id) {
      this.$store.dispatch("incomeClassificationsStore/removeOption", id);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
