import { render, staticRenderFns } from "./IncomeClassification.vue?vue&type=template&id=5b0c3c80&scoped=true&"
import script from "./IncomeClassification.vue?vue&type=script&lang=js&"
export * from "./IncomeClassification.vue?vue&type=script&lang=js&"
import style0 from "./IncomeClassification.vue?vue&type=style&index=0&id=5b0c3c80&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b0c3c80",
  null
  
)

export default component.exports